<template>
  <div>

    <!-- Canvas for particles animation -->
    <div id="particles-js"></div>
    <div class="content">

      <div class="content-box">

        <div class="big-content">

          <!-- Main squares for the content logo in the background -->
          <div class="list-square">
            <span class="square"></span>
            <span class="square"></span>
            <span class="square"></span>
          </div>

          <!-- Main lines for the content logo in the background -->
          <div class="list-line">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>

          <!-- The animated searching tool -->
          <i class="fa fa-search" aria-hidden="true"></i>

          <!-- div clearing the float -->
          <div class="clear"></div>

        </div>

          <!-- Your text -->
          <h1>{{ $t("not-found.not-found-message") }}</h1>

          <p>{{ $t("not-found.not-found-sub-message")}}</p>

          <countdown class="redirect-message" :end-time="endTime" @finish="finishCountdown" v-if="isTimer">
            <template v-slot:process="time">
              <h5 class="text-center">
                <a :href="redirectPath">
                  {{ $t("not-found.redirect-message", { second: time.timeObj.s}) }}
                </a>
              </h5>
            </template>
          </countdown>

      </div>

    </div>

    <Footer/>
  </div>
</template>

<script>

export default {
  name: 'not-found',
  components: {
    countdown: () => import('@/components/atoms/CountDown'),
    Footer: () => import('@/components/HeaderFooter/Footer'),
  },
  data() {
    return {
      prevRoute: null,
      redirectPath: null,
      endTime: null,
      gotRedirectUrl: false,
    };
  },
  computed: {
    isTimer() {
      if (!this.endTime) return false;
      return true;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  async beforeMount() {
    if (this.$route.name !== '404') { await this.notify404(this.$route.path); }
  },
  async mounted() {
    const plugin = document.createElement('script');
    plugin.async = true;
    plugin.setAttribute('type', 'text/javascript');
    plugin.setAttribute('src', '/assets/js/particles.js');
    document.body.appendChild(plugin);

    document.querySelector('body').classList.add('flat-404');

    if (this.$route.name === '404') { await this.notify404(this.prevRoute?.path); }
  },
  created() {
    this.$store.commit('SET_TYPE_CHANNEL', '404-page');
  },
  destroyed() {
    document.querySelector('body').classList.remove('flat-404');
  },
  methods: {
    async notify404(urlNotFound) {
      this.redirectPath = await this.$store.dispatch('NOTIFY_ADMIN_404_ERROR', {
        urlPath: urlNotFound,
      });
      this.endTime = new Date().getTime() + 3000;
      if (this.redirectPath === null) this.redirectPath = '/';
      this.gotRedirectUrl = true;
    },
    finishCountdown() {
      this.goRedirect();
    },
    goRedirect() {
      if (!this.gotRedirectUrl) return;
      // this.$router.push({ path: this.redirectPath });
      window.location = this.redirectPath;
    },
  },
};
</script>

<style scoped>
/* @import '/assets/css/vegas.min.css'; */
a, h1{
  color: white;
}
h1 {
  font-size: 1.875rem;
}
p {
  margin: 0;
  font-size: 0.9375rem;
  line-height: 1.8;
  color: #d2d6e4;
  font-weight: 400;
  text-align: center;
}
#particles-js {
  position: fixed;
  top: 25vh;
  left: 25vw;
  width: 50vw;
  height: 50vh;
  z-index: -10;
}
.logo-link {
  position: absolute;
  z-index: 20;
  display: block;
  top: 1.875rem;
  left: 1.875rem;
  /* width: 3.125rem; */
}

.logo-link .logo {
  width: 100%;
  height: auto;
}

.content {
  height: calc(100vh - 240px);
  overflow: hidden;
  z-index: 10;
  position: relative;
  box-align: center;
  align-items: center;
  display: flex;
  box-pack: center;
  justify-content: center;
}
.content .content-box {
  width: auto;
  position: relative;
}
.content .content-box .big-content {
  position: relative;
  width: 13.75rem;
  min-height: 13.75rem;
  margin: 0 auto 1.875rem;
}
.content .content-box .big-content .list-square {
  float: left;
}
.content .content-box .big-content .list-line {
  float: left;
  margin-left: 1.25rem;
}
.content .content-box .big-content span.square {
  display: block;
  background: transparent;
  width: 3.125rem;
  height: 3.125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  border: 0.625rem solid #FFFFFF;
  margin-bottom: 0.9375rem;
}
.content .content-box .big-content span.line {
  display: block;
  background: #FFFFFF;
  width: 9.375rem;
  height: 0.625rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  margin-bottom: 1.4375rem;
}
.content .content-box .big-content span.line:nth-child(2) {
  width: 6.25rem;
}
.content .content-box .big-content span.line:nth-child(4) {
  width: 6.25rem;
}
.content .content-box .big-content span.line:nth-child(6) {
  width: 6.25rem;
}
.content .content-box .big-content .fa-search {
  position: absolute;
  top: 6.25rem;
  left: 9.375rem;
  font-size: 6.25rem;
  color: #00c8aa;
  -webkit-animation: corner 5s infinite;
  -moz-animation: corner 5s infinite;
  animation: corner 5s infinite;
}
.content .content-box .big-content .fa-search.color {
  color: #FF0084;
}

@-webkit-keyframes corner {
  0% {
    -webkit-transform: translate(-1.25rem, 0);
    -webkit-animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -webkit-transform: translate(-9.375rem, 1.25rem);
  }
  40% {
    -webkit-transform: translate(-4.375rem, -4.375rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -webkit-transform: translate(-9.375rem, -6.25rem);
  }
  80% {
    -webkit-transform: translate(1.25rem, -7.5rem);
  }
  100% {
    -webkit-transform: translate(-1.25rem, 0);
  }
}
@-moz-keyframes corner {
  0% {
    -moz-transform: translate(-1.25rem, 0);
    -moz-animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -moz-transform: translate(-9.375rem, 1.25rem);
  }
  40% {
    -moz-transform: translate(-4.375rem, -4.375rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -moz-transform: translate(-9.375rem, -6.25rem);
  }
  80% {
    -moz-transform: translate(1.25rem, -7.5rem);
  }
  100% {
    -moz-transform: translate(-1.25rem, 0);
  }
}
@keyframes corner {
  0% {
    -webkit-transform: translate(-1.25rem, 0);
    -moz-transform: translate(-1.25rem, 0);
    -ms-transform: translate(-1.25rem, 0);
    -o-transform: translate(-1.25rem, 0);
    transform: translate(-1.25rem, 0);
    -webkit-animation-timing-function: 0, 0.02, 0, 1.01;
    -moz-animation-timing-function: 0, 0.02, 0, 1.01;
    animation-timing-function: 0, 0.02, 0, 1.01;
  }
  20% {
    -webkit-transform: translate(-9.375rem, 1.25rem);
    -moz-transform: translate(-9.375rem, 1.25rem);
    -ms-transform: translate(-9.375rem, 1.25rem);
    -o-transform: translate(-9.375rem, 1.25rem);
    transform: translate(-9.375rem, 1.25rem);
  }
  40% {
    -webkit-transform: translate(-4.375rem, -4.375rem);
    -moz-transform: translate(-4.375rem, -4.375rem);
    -ms-transform: translate(-4.375rem, -4.375rem);
    -o-transform: translate(-4.375rem, -4.375rem);
    transform: translate(-4.375rem, -4.375rem);
    animation-timing-function: cubic-bezier(0, 0.02, 0, 1.01);
  }
  60% {
    -webkit-transform: translate(-9.375rem, -6.25rem);
    -moz-transform: translate(-9.375rem, -6.25rem);
    -ms-transform: translate(-9.375rem, -6.25rem);
    -o-transform: translate(-9.375rem, -6.25rem);
    transform: translate(-9.375rem, -6.25rem);
  }
  80% {
    -webkit-transform: translate(1.25rem, -0.70325rem);
    -moz-transform: translate(1.25rem, -0.70325rem);
    -ms-transform: translate(1.25rem, -0.70325rem);
    -o-transform: translate(1.25rem, -0.70325rem);
    transform: translate(1.25rem, -0.70325rem);
  }
  100% {
    -webkit-transform: translate(-1.25rem, 0);
    -moz-transform: translate(-1.25rem, 0);
    -ms-transform: translate(-1.25rem, 0);
    -o-transform: translate(-1.25rem, 0);
    transform: translate(-1.25rem, 0);
  }
}
/* footer {
  color: #FFFFFF;
  text-align: center;
  position: absolute;
  z-index: 20;
  padding: 0.625rem 0;
  bottom: 0;
  left: 0;
  width: 100%;
  direction: ltr;
}
footer ul li {
  position: relative;
  display: inline-block;
  padding: 0;
}
footer ul li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0.125rem;
  height: 100%;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  border-radius: 0.625rem;
  background: #735dd1;
}
footer ul li:last-child::after {
  display: none;
}
footer ul li a {
  text-transform: uppercase;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 1.25rem 0 0.875rem;
}
footer ul li a:hover {
  color: #6249cc;
}
footer ul li a:hover::after {
  opacity: 1;
  -webkit-transform: translateY(0) translateX(-60%);
  -moz-transform: translateY(0) translateX(-60%);
  -ms-transform: translateY(0) translateX(-60%);
  -o-transform: translateY(0) translateX(-60%);
  transform: translateY(0) translateX(-60%);
}
footer ul li a::after {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 40%;
  height: 0.4rem;
  background: rgba(98, 73, 204, 0.4);
  content: '';
  opacity: 0;
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  -ms-border-radius: 0.625rem;
  border-radius: 0.625rem;
  -webkit-transform: translateY(0.625rem) translateX(-60%);
  -moz-transform: translateY(0.625rem) translateX(-60%);
  -ms-transform: translateY(0.625rem) translateX(-60%);
  -o-transform: translateY(0.625rem) translateX(-60%);
  transform: translateY(0.625rem) translateX(-60%);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
footer.light ul li::after {
  width: 0.0625rem;
  background: rgba(117, 122, 134, 0.2);
}
footer.light ul li a {
  color: rgba(255, 255, 255, 0.7);
}
footer.light ul li a:hover {
  color: #FFFFFF;
}
footer.light ul li a::after {
  background: rgba(255, 255, 255, 0.3);
} */
</style>
<style>
body.flat-404 {
  background: #8471d7;
}
</style>
